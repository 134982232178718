import React, { useEffect, useRef, useState } from 'react';
import DetailsNavigation from './DetailsNavigation/DetailsNavigation';
import "./CustomerDetails.scss"
import Loader from '../../LoaderNew/Loader';
import FileTab from './FileTab/FileTab';
import SystemTab from './SystemTab/SystemTab';
import OrderTab from './OrderTab/OrderTab';
import QuoteTab from './QuoteTab/QuoteTab';
import { FILES, SYSTEMS } from '../../../constants/translations/review-popup';
import { DESKTOP_VIEW_TRANSLATION } from '../../../constants/translations/customer/desktop/desktopView';
import SystemTabNew from './SystemTabNew/SystemTabNew';
import { useSelector } from 'react-redux';
import { SERVICE } from '../../../constants';


let navigationData = {
    navigationFor: 'normal',
    navigationTab: [
        { label: DESKTOP_VIEW_TRANSLATION.ORDERS, value: 0 },
        { label: DESKTOP_VIEW_TRANSLATION.QUOTES, value: 0 },
        { label: SYSTEMS, value: 0 },
        { label: FILES, value: 0 },
    ]
}

export default function CustomerDetails(props) {
    const { customer, lastCreatedOrderId, customerId } = props;
    const [reloadEffect, setReloadEffect] = useState(0);
    const [selectedTab, setSelectedTab] = useState(0);
    const [showPopUp, setShowPopUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [navLength, setNavLength] = useState({
        files: 0,
        orders: 0,
        systems: 0,
        quotes: 0

    })

    const factoryType = useSelector(state => state?.login?.user?.type_of_factory)
    const accordionDataTree = useSelector((state) => state.customerSystems.systemsForAccordion);


    const { customerData, setCustomerOrders, setCustomerQuotes } = useSelector((state) => state.customersPage);
    const filesLength = customerData?.attachments ? customerData?.attachments.length : 0;
    const OrdersLength = setCustomerOrders ? setCustomerOrders.length : 0;
    const quotesLength = setCustomerQuotes ? setCustomerQuotes.length : 0;
    const accordionDataTreeLength = accordionDataTree ? accordionDataTree.length : 0;
    

    navigationData.navigationTab = [
        { label: DESKTOP_VIEW_TRANSLATION.ORDERS, value: navLength.orders },
        { label: DESKTOP_VIEW_TRANSLATION.QUOTES, value: navLength.quotes },
        { label: SYSTEMS, value: navLength.systems },
        { label: FILES, value: navLength.files },
    ]


    function changeSelectionTab(newValue) {
        setLoading(true)
        setSelectedTab(newValue);
        setReloadEffect((prev) => prev + 1);
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }, [reloadEffect])

    useEffect(()=>{
        setNavLength({
            files: filesLength,
            orders: OrdersLength,
            systems: accordionDataTreeLength,
            quotes: quotesLength
        })
    }, [OrdersLength, quotesLength, filesLength, accordionDataTreeLength])

    function handleShowPopUp() {
        setShowPopUp(!showPopUp)
    }

    return (
        <>
            <div className={props.isCustomerPage ? '' : 'desktopView'}>
                <DetailsNavigation tabNumber={selectedTab} changeTab={changeSelectionTab} data={navigationData} />
            </div>
            {loading ? <Loader /> : null}
            {
                selectedTab === 2 ? <>
                    {
                        factoryType === SERVICE ?
                            <SystemTabNew
                                customer={customer && customer}
                                systems={customer?.systems}
                                view={props.view}
                            />
                            : <SystemTab
                                customer={customer}
                                systems={customer.systems}
                                view={props.view}
                            />
                    }
                </>
                    : selectedTab === 3 ? <FileTab
                        showPopUp={showPopUp}
                        handleShowPopUp={handleShowPopUp}
                        customerId={customerId}
                        files={customer && customer?.attachments}
                        customer={customer && customer}
                        selectedTab={selectedTab}
                    />
                        : selectedTab === 0 ? <OrderTab
                            showPopUp={showPopUp}
                            handleShowPopUp={handleShowPopUp}
                            customerId={customerId && customerId}
                            lastCreatedOrderId={lastCreatedOrderId && lastCreatedOrderId}
                            customer={customer && customer}
                            selectedTab={selectedTab}
                        />
                            : selectedTab === 1 ? <QuoteTab
                                showPopUp={showPopUp}
                                handleShowPopUp={handleShowPopUp}
                                customer={customer}
                                selectedTab={selectedTab}
                            />
                                : null
            }
        </>

    )
}

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import "./PaymentDetails.scss";
import { Grid } from '@material-ui/core';
import Table from '../../Customer/Table/Table2';
import Button from '../../Customer/Buttons/Button';
import AddStepPopUp from '../../Customer/PopUps/AddStepPopUp/AddStepPopUp';
import { getOrderSystems } from '../../../functions/api/orders';
import { add, cancel, paymentDetailsTableData, step } from './PaymentDetailsStatic';


export default function PaymentDetails(props) {
    const { handleShowPopUp, showPopUp, processesOrder, processesDataNames, processesDataIds, formattedValue, handleClosePopup, orderId } = props;
    const [showStepPopUp, setShowStepPopUp] = useState(false);
    const [paymentDetailsTableRows, setPaymentDetailsTableRows] = useState([])


    function handleShowStepPopUp() {
        setShowStepPopUp(!showStepPopUp)
    }



    const addProcessOrderStage = (value) => {
        processesOrder.stages.unshift({
            ...value,
        });
        handleShowStepPopUp();
    }
    
    const paymentTableList = [];
    async function getFormData() {
        const formData = await getOrderSystems(orderId)
        const ordersPercentSelectionOptions = [10, 20, 30, 40]

        const data = formData.result?.collection_stages.length > 0 && formData.result?.collection_stages.forEach((stage, index) => {
         
            const idx = processesDataIds.findIndex((item) => {
                return item === stage.process_id;
            });

            let tableRow = {
                id: index, 
                stepID: 'S00011'+index, 
                stepName: processesDataNames[idx] ? processesDataNames[idx] : '-', 
                percent: { placeholder: stage.percentage, value: stage.percentage, selectionOptions: ordersPercentSelectionOptions }, 
                sum: stage.value? stage.value : '-', 
                reminder: stage.days? stage.days : '-'
            }
           
            paymentTableList.push(tableRow);
        })


       
        setPaymentDetailsTableRows(() => paymentTableList)
    }
  
    paymentDetailsTableData.rows.push(...paymentDetailsTableRows);
  
    useEffect(() => {
        getFormData()
    }, [ orderId])


    return (
        <>
            <div className='paymentDetails__main'>
                <Table data={paymentDetailsTableData} tableFor="paymentDetails" view="desktop"/>
                <Grid xs={12} className='paymentDetails__btn__container addOrderFooter'>
                    <Grid xs={6} className='paymentDetails__btn__container__left'>
                        <Button data={step} handleClick={() => handleShowStepPopUp()} />
                    </Grid>
                    <Grid xs={6} className='paymentDetails__btn__container__right'>
                        <Button data={add} handleClick={() => handleShowPopUp()} />
                        &nbsp;
                        &nbsp;
                        &nbsp;
                        <Button data={cancel} handleClick={() => handleClosePopup()} />
                    </Grid>
                </Grid>
            </div>
            {
                showStepPopUp ? <AddStepPopUp showPopUp={showStepPopUp} handleShowPopUp={handleShowStepPopUp} processesDataNames={processesDataNames} processesOrder={processesOrder} processesDataIds={processesDataIds} addProcessOrderStage={addProcessOrderStage} /> : null
            }

        </>
    )
}

import { CHECK_LIST_TRANSLATION } from "../translations/customersPage"

export const mobileSystemsCheckList = {
    view: 'mobile',
    popUpData: [
        {
            label: "Open File",
            icon: 'folder'
        },
        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
    ],
    columns: [
        {
            field: 'description',
            headerName: 'Description',
        },
        {
            field: 'normalAbnormal',
            headerName: 'Normal / Abnormal',
        },
       
        {
            field: 'comments',
            headerName: 'Comments',
        },
        {
            field: 'photo',
            headerName: 'Photo',
        }
    ],
    rows: [
        { id: 1, description: 'Porem ipsum dolor sit ...', normalAbnormal: 'Abnormal', causeOfAbnormality: 'Gorem ipsum', requiredAction: 'Gorem ipsum', fixedOnTheSpot: { name: '', border: 'none', isBoxShadow: false, background: 'transparent' }, comments: 'Morem ipsum dolor', progress: '70%', photo: 'Upload photo' },
        { id: 2, description: 'Porem ipsum dolor sit ...', normalAbnormal: 'Abnormal', causeOfAbnormality: 'Gorem ipsum', requiredAction: 'Gorem ipsum', fixedOnTheSpot: { name: '', border: 'none', isBoxShadow: false, background: 'transparent' }, comments: 'Morem ipsum dolor', progress: '70%', photo: 'See photo (1)' },
        { id: 3, description: 'Porem ipsum dolor sit ...', normalAbnormal: 'Abnormal', causeOfAbnormality: 'Gorem ipsum', requiredAction: 'Gorem ipsum', fixedOnTheSpot: { name: '', border: 'none', isBoxShadow: false, background: 'transparent' }, comments: 'Morem ipsum dolor', progress: '70%', photo: 'Upload photo' },
        { id: 4, description: 'Porem ipsum dolor sit ...', normalAbnormal: 'Abnormal', causeOfAbnormality: 'Gorem ipsum', requiredAction: 'Gorem ipsum', fixedOnTheSpot: { name: '', border: 'none', isBoxShadow: false, background: 'transparent' }, comments: 'Morem ipsum dolor', progress: '70%', photo: 'Upload photo' },
    ],
}

export const tabletSystemsCheckList = {
    view: 'tablet',
    popUpData: [
        {
            label: "Open File",
            icon: 'folder'
        },
        {
            label: "Edit File",
            icon: 'edit'
        },
        {
            label: "Delete File",
            icon: 'delete'
        },
    ],
    columns: [
        {
            field: 'description',
            headerName: CHECK_LIST_TRANSLATION.TEST_DESCRIPTION,
        },
        {
            field: 'normalAbnormal',
            headerName: CHECK_LIST_TRANSLATION.PROPER_NOT_PROPER,
        },
        {
            field: 'comments',
            headerName: CHECK_LIST_TRANSLATION.COMMENTS,
        },
        {
            field: 'photo',
            headerName: CHECK_LIST_TRANSLATION.IMAGE,
        },
        {
            field: 'progress',
            headerName: CHECK_LIST_TRANSLATION.PROGRESS,
        },
    ],
    rows: [],
}

export const systemsAccordion = [
    {
        id: 1,
        data: [{
            hasMore: true,
            layer: 1,
            items: [
                {
                    name: "System name",
                    details: 'Electrical system building',
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
                {
                    name: "Location description",
                    details: 6,
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
                {
                    name: "Floor",
                    details: 6,
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
            ],
            data: [{
                hasMore: true,
                layer: 2,
                items: [
                    {
                        name: 'Number of boards',
                        details: 6,
                        nameColor: '#3EB0A7',
                        nameBackground: '#F2F5ED'
                    }
                ],
                data: [{
                    hasMore: true,
                    layer: 3,
                    items: [
                        {
                            name: 'Number of blowers in the stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        },
                        {
                            name: '# of shelves in the stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        },
                        {
                            name: '# of emotional pressure in a stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        }
                    ],
                    data: [{
                        hasMore: true,
                        layer: 4,
                        items: [
                            {
                                name: 'Designation of the blower',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            },
                            {
                                name: 'Model / blower diameter',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            },
                            {
                                name: 'Planned airflow for blower',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            }
                        ],
                        data: [{
                            hasMore: false,
                            layer: 5,
                            items: [
                                {
                                    name: 'Designation of the blower',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                },
                                {
                                    name: 'Model / blower diameter',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                },
                                {
                                    name: 'Planned airflow for blower',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                }
                            ],

                        }]
                    }]
                }]
            }]
        },
        {
            hasMore: true,
            layer: 1,
            items: [
                {
                    name: "System name",
                    details: 'Electrical system building',
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
                {
                    name: "Location description",
                    details: 6,
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
                {
                    name: "Floor",
                    details: 6,
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
            ],
            data: [{
                hasMore: true,
                layer: 2,
                items: [
                    {
                        name: 'Number of boards 11',
                        details: 6,
                        nameColor: '#3EB0A7',
                        nameBackground: '#F2F5ED'
                    }
                ],
                data: [{
                    hasMore: true,
                    layer: 3,
                    items: [
                        {
                            name: 'Number of blowers in the stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        },
                        {
                            name: '# of shelves in the stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        },
                        {
                            name: '# of emotional pressure in a stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        }
                    ],
                    data: [{
                        hasMore: true,
                        layer: 4,
                        items: [
                            {
                                name: 'Designation of the blower',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            },
                            {
                                name: 'Model / blower diameter',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            },
                            {
                                name: 'Planned airflow for blower',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            }
                        ],
                        data: [{
                            hasMore: false,
                            layer: 5,
                            items: [
                                {
                                    name: 'Designation of the blower',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                },
                                {
                                    name: 'Model / blower diameter',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                },
                                {
                                    name: 'Planned airflow for blower',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                }
                            ],

                        }]
                    }]
                }]
            }]
        }
        ]
    },
    {
        id: 10,
        data: [{
            hasMore: true,
            layer: 1,
            items: [
                {
                    name: "System name 10",
                    details: 'Electrical system building',
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
                {
                    name: "Location description",
                    details: 6,
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
                {
                    name: "Floor",
                    details: 6,
                    nameColor: '#81B03E',
                    nameBackground: '#F2F5ED'
                },
            ],
            data: [{
                hasMore: true,
                layer: 2,
                items: [
                    {
                        name: 'Number of boards',
                        details: 6,
                        nameColor: '#3EB0A7',
                        nameBackground: '#F2F5ED'
                    }
                ],
                data: [{
                    hasMore: true,
                    layer: 3,
                    items: [
                        {
                            name: 'Number of blowers in the stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        },
                        {
                            name: '# of shelves in the stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        },
                        {
                            name: '# of emotional pressure in a stairwell',
                            details: 6,
                            nameColor: '#BD1657',
                            nameBackground: '#FCEEF3'
                        }
                    ],
                    data: [{
                        hasMore: true,
                        layer: 4,
                        items: [
                            {
                                name: 'Designation of the blower',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            },
                            {
                                name: 'Model / blower diameter',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            },
                            {
                                name: 'Planned airflow for blower',
                                details: 6,
                                nameColor: '#3E54B0',
                                nameBackground: '#FCEEF3'
                            }
                        ],
                        data: [{
                            hasMore: false,
                            layer: 5,
                            items: [
                                {
                                    name: 'Designation of the blower',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                },
                                {
                                    name: 'Model / blower diameter',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                },
                                {
                                    name: 'Planned airflow for blower',
                                    details: 7,
                                    nameColor: '#C532C4',
                                    nameBackground: '#FDEEFD'
                                }
                            ],

                        }]
                    }]
                }]
            }]
        }]
    }
]
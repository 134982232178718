import React from 'react';
import "./EmptyList.scss";

export default function EmptyList(props) {
  const {name} = props;
  return (
    <div className='Empty-container'>
    {/* <p>{TABLET_VIEW_TRANSLATION.EMPTY_SYSTEMS_LIST}</p> */}
    {/* <p>There is no {name} available</p> */}
      <p>  אין {name} זמין</p>
    </div>
  )
}

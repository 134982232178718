import React from 'react';
import "./CheckListPopUp.scss";
import { Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { CANCEL, SAVE } from '../../../../constants/translations/review-popup';
import Button from '../../Buttons/Button';
import { systemActionsCreator } from '../../../../actions/customer/systemActions';

import { DESKTOP_VIEW_TRANSLATION } from '../../../../constants/translations/customer/desktop/desktopView';


const fileTabBtnsDesktop = {
    checkList: {
        name: 'Check List',
        color: '#fff',
        backgroundColor: "#186315",
        fontWeight: 800,
        icon: true,
        iconContainer: true,
        iconContainerWidth: '32px',
        border: false,
        buttonName: 'AddIcon',
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        iconContainerWidth: '26px',
        width: '40%',
    },
    save: {
        name: SAVE,
        color: '#fff',
        backgroundColor: "#0091FF",
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: false,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        width: '40%',

    },
    cancel: {
        name: CANCEL,
        color: '#0091FF',
        backgroundColor: "#fff",
        fontWeight: 800,
        icon: false,
        iconContainer: false,
        border: true,
        height: '52px',
        fontSize: '16px',
        borderRadius: '20px',
        width: '40%',
    }
}

export default function CheckListPopUp(props) {
    const { handleShowPopUp, systemsTableData, singleSystemAccordionRow } = props;

    const dispatch = useDispatch();
    const handleAddCheckList = () => {
        dispatch(systemActionsCreator.addCheckList());
    }
    return (
        <div
            className='checkListPopup__container'
        >
            <Typography variant='h4' className='checkListPopup__title'>
               {DESKTOP_VIEW_TRANSLATION.CHECK_LIST}
            </Typography>
            <Grid xs={12} className='checkListPopup__systemContainer'>
                {
                    singleSystemAccordionRow && singleSystemAccordionRow.map((item, index) => {
                        return <Grid xs={4} className='checkListPopup__col' key={index}>
                            <Typography className='checkListPopup__systemTitle'>{item.name}</Typography>
                            <Typography className='checkListPopup__details'>{item.details}</Typography>
                        </Grid>
                    })
                }
            </Grid>
            <div className='checkListPopup__table'>
               
            </div>
            <Grid xs={12} className='fileTab__btnRow'>
                <Grid xs={6} className='fileTab__btnRow-right'>
                    <Button data={fileTabBtnsDesktop.save} />
                    &nbsp;
                    &nbsp;
                    <Button data={fileTabBtnsDesktop.cancel} handleClick={() => handleShowPopUp(false)}  />
                </Grid>
                <Grid xs={6} className='fileTab__btnRow-left'>
                    <Button data={fileTabBtnsDesktop.checkList} handleClick={() => handleAddCheckList()} />
                </Grid>
            </Grid>
        </div>
    )
}

import React, { useState, useEffect, useMemo } from 'react'
import Grid from '@material-ui/core/Grid';
import CustomerInfo from './CustomerInfo/CustomerInfo'
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerInfoById} from '../../actions/customers_table_actions'
import { useStyles } from './styles/CustomersTable.styles'
import { createMuiTheme } from "@material-ui/core/styles";
import { StylesProvider, ThemeProvider, jssPreset } from "@material-ui/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { METALPRESS, SERVICE } from '../../constants/factories'
import { clearQuoteData } from '../../actions/quotation-actions'
// import { updateCustomerFirstLevelSystems } from '../../actions/customers_table_actions';
// import CustomersPageService from './Service/CustomersPage'
import LoaderNew from '../LoaderNew/Loader'
import Stripe from '../reused-components/Stripe'
import Tabs from './Tabs/Tabs';
import EditCustomerSection from './EditCustomerSection/editCustomerSection'
import { updateCustomerFirstLevelSystems, setCallbackAndCredentialsForAddingSystem } from '../../actions/actions'
import BackNavigation from '../reused-components/BackNavigation';
// import '../../sass/customersPage/_customer-page.scss';

import CustomerInfoEditSection from '../Customer/CustomerInfoEditSection/CustomerInfoEditSection';
import CustomerDetails from '../Customer/CustomerDetails/CustomerDetails';
import CustomerInfoSidebar from '../Customer/CustomerInfoSidebar/CustomerInfoSidebar';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
  direction: "rtl"
});

const CustomersPage = (props) => {
  const classes = useStyles()
  const [loader, setLoader] = useState(true)
  const customerId = props.match.params.customerIdentifier
  const customerData = useSelector((state) => state.customersPage.customerData);
  const set_system_view = useSelector((state) => state.customersPage.set_system_view);
  const dispatch = useDispatch()
  const lastCreatedOrderId = useSelector(state => state.customersPage.createOrderFromCustomerPageResult)
  const factoryType = useSelector(state => state?.login?.user?.type_of_factory)


  useEffect(() => {
    // set credentials for adding systems
    const handleDataForAddingSystem = () => {
      const FIRST_LAYER =1
      const data = {
        updateSystems: (systems) => dispatch(updateCustomerFirstLevelSystems(systems)),
        parentSystemId: null, /*first layer doesn't have parent system */
        layer: FIRST_LAYER
      }
      dispatch(setCallbackAndCredentialsForAddingSystem(data))
    }

    handleDataForAddingSystem()
    dispatch(getCustomerInfoById(customerId))
    dispatch(clearQuoteData())
  },[props.match.params.customerIdentifier])

  useEffect(() => {
    let time_out = setTimeout(() => setLoader(false),0);
    return () => clearTimeout(time_out);
  },[])

  return (
    <>
    {loader && <LoaderNew />}
      {customerData &&
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>
            <Stripe>
              <BackNavigation/>
            </Stripe>
        {
              factoryType === SERVICE ? <Grid
                className={`${classes.customersPageWrapper} ${classes.customer_page_over}`}
              >
                <EditCustomerSection />
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <CustomerInfo
                    customer={customerData}
                  />
                  <Tabs
                    customerId={customerId}
                    lastCreatedOrderId={lastCreatedOrderId}
                    customer={customerData}
                    system_view={set_system_view}
                  />
                </Grid>
              </Grid> 
              : 
                <div style={{ margin: '10px 20px'}}>
                  <CustomerInfoEditSection />
                  <Grid container>
                    <Grid xs={3} style={{ padding: '10px 0px 10px 10px' }}>
                      <CustomerInfoSidebar customer={customerData} />
                    </Grid>
                    <Grid xs={9} style={{ width: '80%', height: '400px', padding: '10px 0px 10px 0px' }}>
                      <CustomerDetails
                        view={'desktop'}
                        isCustomerPage={true}
                        customerId={customerId}
                        lastCreatedOrderId={lastCreatedOrderId}
                        customer={customerData}
                      />
                    </Grid>
                  </Grid>
                </div>
            }
           

          
          </ThemeProvider>
        </StylesProvider>
      }
    </>
  )
}

export default CustomersPage

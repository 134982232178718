import { Typography } from '@material-ui/core'
import React, { Fragment, useEffect } from 'react'
import "./SystemsList.scss";
import AccordionMain from '../Accordion/AccordionMain';
import Loader from '../../../../../components/LoaderNew/Loader';
import { TABLET_VIEW_TRANSLATION } from '../../../../../constants/translations/customer/tablet/tabletView';
import { useDispatch, useSelector } from 'react-redux';
import EmptyList from '../EmptyList/EmptyList';
import TabEmptyLanding from '../../../MobileViews/TabEmptyLanding/TabEmptyLanding';
import { systemActionsCreator } from '../../../../../actions/customer/orderProcessSystemActions';

function getSystemPath(pathArray) {
  return pathArray.map((openedSystem, index) => {
    return <span key={openedSystem.systemName + index}>
      {
        (pathArray.length > 1 &&( index >= pathArray.length - 1)) ? <span> &#62;</span> : null
      }
      <span className={index === 0 ? 'activeSystem': null}>{openedSystem.systemName}</span>
    </span>
  })
}

export default function SystemsList(props) {
  const { popupClickHandlers, onClickHandler, process, setSelectedParentSystem,  selectedParentSystem } = props;
  const orderId = process && (process.order_id || process.orderId);
 const processId = process.processId
  const dispatch = useDispatch()
  
  const isChecklistLoading = useSelector((state) => state.orderProcessSystemReducer.isChecklistLoading);
  const accordionDataTree = useSelector((state) => state.orderProcessSystemReducer.systemsForAccordion);
  const systemsOpeningPath = useSelector((state) => state.orderProcessSystemReducer.systemsOpeningPath);
  useEffect(() => {
    dispatch(systemActionsCreator.getSystemsByOrderId(orderId, processId));

  }, [orderId, processId])

  const view = 'tablet'
  return (
    <>
      <h4 className='system-tab-main__title'>{TABLET_VIEW_TRANSLATION.SYSTEMS_LIST}</h4>
      <Typography variant="caption" className='system-tab-main__text'>{getSystemPath(systemsOpeningPath)}</Typography>
      <div className='accordion__list'>
        {
          accordionDataTree.length ?
          accordionDataTree.map((accordionData, index) => {
            return <Fragment key={index}>
              <AccordionMain
                path={`[${index}]`}
                data={accordionData.data}
                systemId={accordionData.systemId}
                view={view}
                onClickHandler={onClickHandler}
                popupClickHandlers={popupClickHandlers}
                rowIndex={index}
                setSelectedParentSystem={setSelectedParentSystem}
                selectedParentSystem={selectedParentSystem}
                process={process}
              />
            </Fragment>
          })
            : view !== 'mobile' ? (isChecklistLoading ? <Loader /> : <EmptyList name={'system'} />) : <TabEmptyLanding title={'system'} />
        }
      </div>
    </>
  )
}

import React, { useState } from 'react'
import AccordionRow from './AccordionRow';
import AngleArrowImg from '../../assets/AngleArrow-img.svg';
import "./AccordionMain.scss"

export default function AccordionMain(props) {
    const { data, onClickHandler, popupClickHandlers, rowIndex, selectedParentSystem, setSelectedParentSystem, process, path = '' } = props;
    let { view } = props;
    const [isNextLayerVisible, setIsNextLayerVisible] = useState(false);

    const funcExpand = () => {
        setIsNextLayerVisible(!isNextLayerVisible);
    };

    // changing the view for this component only, so the UI will same fot both the case
    if (view === 'desktop-popup') {
        view = 'desktop'
    }
    
    return (
        <React.Fragment key={rowIndex}>

            {
                data?.map((_data, _index) => {
                    if (_data.data.length) {
                        return <React.Fragment key={_data.systemId}>
                            <React.Fragment key={_data.systemId}>
                                <AccordionRow
                                    path={`${path}.data[${_index}]`}
                                    rowIndex={rowIndex}
                                    systemId={_data.systemId}
                                    data={_data}
                                    isIcon={_data.data.length ? true : false}
                                    showNext={funcExpand}
                                    view={view}
                                    onClickHandler={onClickHandler}
                                    popupClickHandlers={popupClickHandlers}
                                    nextChildSystemData={_data}
                                    setSelectedParentSystem={setSelectedParentSystem}
                                    selectedParentSystem={selectedParentSystem}
                                    process={process}
                                />
                            </React.Fragment>
                            {
                                view === 'desktop' ?
                                    <>
                                        {
                                            isNextLayerVisible && <div
                                                key={_index}
                                                className='accordion__nextRow'
                                                style={{ marginRight: view === 'desktop' && '20px' }}
                                            >
                                                {data.length > 1 && _index !== 0 ? <span className='verticalLine'></span> : null}
                                                <span><img src={AngleArrowImg} alt='icon' /></span>
                                                <span style={{ width: '100%' }}>
                                                    <AccordionMain
                                                        path={`${path}.data[${_index}]`}
                                                        rowIndex={_index}
                                                        showNext={funcExpand}
                                                        data={_data.data}
                                                        view={view}
                                                        onClickHandler={onClickHandler}
                                                        popupClickHandlers={popupClickHandlers}
                                                        setSelectedParentSystem={setSelectedParentSystem}
                                                        selectedParentSystem={selectedParentSystem}
                                                        process={process}
                                                    />
                                                </span>
                                            </div>
                                        }
                                    </> : <>
                                        {
                                            isNextLayerVisible && <React.Fragment key={_index}>
                                                <AccordionMain
                                                    path={`${path}.data[${_index}]`}
                                                    rowIndex={_index}
                                                    showNext={funcExpand}
                                                    data={_data.data}
                                                    view={view}
                                                    onClickHandler={onClickHandler}
                                                    popupClickHandlers={popupClickHandlers}
                                                    setSelectedParentSystem={setSelectedParentSystem}
                                                    selectedParentSystem={selectedParentSystem}
                                                    process={process}
                                                />
                                            </React.Fragment>

                                        }
                                    </>
                            }
                        </React.Fragment>
                    } else {
                        return (
                            <React.Fragment key={_index}>
                                <AccordionRow
                                    path={`${path}.data[${_index}]`}
                                    rowIndex={rowIndex}
                                    systemId={_data.systemId}
                                    data={_data}
                                    isIcon={_data.data.length ? true : false}
                                    showNext={funcExpand}
                                    view={view}
                                    onClickHandler={onClickHandler}
                                    popupClickHandlers={popupClickHandlers}
                                    nextChildSystemData={_data}
                                    setSelectedParentSystem={setSelectedParentSystem}
                                    selectedParentSystem={selectedParentSystem}
                                    process={process}
                                />
                            </React.Fragment>
                        )
                    }

                })
            }
        </React.Fragment>
    )
}

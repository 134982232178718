export const GET_SYSTEMS_CHECK_LIST_MOBILE = "ORDER_PROCESS_GET_SYSTEMS_CHECK_LIST_MOBILE";
export const GET_SYSTEMS_CHECK_LIST_TABLET = "ORDER_PROCESS_GET_SYSTEMS_CHECK_LIST_TABLET";
export const GET_SYSTEMS_BY_ORDER_ID = "ORDER_PROCESS_GET_SYSTEMS_BY_ORDER_ID";
export const GET_SYSTEMS_FOR_ACCORDION = "ORDER_PROCESS_GET_SYSTEMS_FOR_ACCORDION";
export const SET_SYSTEMS_FOR_ACCORDION = "ORDER_PROCESS_SET_SYSTEMS_FOR_ACCORDION";
export const ADD_SYSTEM = "ORDER_PROCESS_ADD_SYSTEM";
export const SELECTED_SYSTEM_BY_SYSTEM_ID = "ORDER_PROCESS_SELECTED_SYSTEM_BY_SYSTEM_ID";
export const EDIT_SYSTEM = "ORDER_PROCESS_EDIT_SYSTEM";
export const DELETE_SYSTEM = "ORDER_PROCESS_DELETE_SYSTEM";
export const GET_CHECK_LIST_BY_SYSTEM_ID = "ORDER_PROCESS_GET_CHECK_LIST_BY_SYSTEM_ID";
export const GET_SYSTEM_PROGRESS_BY_SYSTEM_ID = "ORDER_PROCESS_GET_SYSTEM_PROGRESS_BY_SYSTEM_ID";
export const MOVE_SYSTEM_ROW = "ORDER_PROCESS_MOVE_SYSTEM_ROW";
export const ADD_CHECK_LIST = "ORDER_PROCESS_ADD_CHECK_LIST";
export const SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH = "ORDER_PROCESS_SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH";
export const REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH = "ORDER_PROCESS_REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH";
export const ADD_SYSTEM_MEASUREMENT = "ORDER_PROCESS_ADD_SYSTEM_MEASUREMENT";
export const UPDATE_SYSTEM_CHECKLIST = "ORDER_PROCESS_UPDATE_SYSTEM_CHECKLIST";
export const SHOW_SYSTEMS_POP_UP = "ORDER_PROCESS_SHOW_SYSTEMS_POP_UP";
export const RESET_SYSTEMS_FOR_ACCORDION = "ORDER_PROCESS_RESET_SYSTEMS_FOR_ACCORDION";

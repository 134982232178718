import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteIcon from '@material-ui/icons/Delete';
import Sign_icon from '../assets/Sign_icon.svg';
import Excel_file_icon from '../assets/Excel_file_icon.svg';
import "./ButtonsContainer.scss"
import { SmallLoader } from '../../reused-components/SmallLoader';



export default function Button({ data, view, isLoading, handleClick }) {
  const { name, width, backgroundColor, color, fontSize, fontWeight, iconContainer, border, buttonName, isBoxShadow, setShowAddFilePopUp, icon, padding, height, borderRadius, iconContainerColor, iconContainerWidth, borderColor, direction } = data;
  const boxShadow = isBoxShadow ? `0px 0px 0px 0px #0844721A, 0px 1px 2px 0px #0844721A, 0px 3px 3px 0px #08447217, 0px 6px 4px 0px #0844720D, 0px 11px 5px 0px #08447203, 0px 18px 5px 0px #08447200` : `0px 0px 0px 0px #fff`

  let requiredBackGroundColor = 'transparent';
  if (iconContainer) {
    requiredBackGroundColor = color
  }
  if (iconContainerColor) {
    requiredBackGroundColor = iconContainerColor
  }

  const renderBtnIcon = () => {
    if (buttonName === 'ArrowBackIosIcon') {
      return <ArrowBackIosIcon style={{ paddingTop: '2px', fontSize: fontSize, fontWeight: fontWeight, color: iconContainer ? '#186315' : "#ffffff" }} />
    } else if (buttonName === 'Excel') {
      return <img src={Excel_file_icon} alt="icon" />
    } else if (buttonName === 'Stop') {
      return <StopIcon className='button__icon-xl button__icon-fff' style={{ fontWeight: fontWeight }} />
    } else if (buttonName === 'sign') {
      return <img className={view === 'desktop' ? 'button__imgIcon--desktop' : 'button__imgIcon'} src={Sign_icon} alt='icon' />
    } else if (buttonName === 'play') {
      return <PlayArrowIcon className='button__icon-xl button__icon-fff' style={{ fontWeight: fontWeight, color: iconContainer && "#ffffff" }} />
    } else if (buttonName === 'delete') {
      return <DeleteIcon style={{ fontSize: view === 'desktop' ? '24px' : '14px', fontWeight: fontWeight, color: color }} />
    } else {
      return <AddIcon style={{ fontSize: fontSize, fontWeight: fontWeight, color: iconContainer ? '#186315' : "#ffffff" }} />
    }
  }

  return (
    <div
      onClick={handleClick}
      className='button'
      style={{
        width: width,
        background: backgroundColor,
        border: border ? `1px solid  ${color}` : 'none',
        borderColor: borderColor && borderColor,
        boxShadow: boxShadow,
        padding: padding && padding,
        height: height && height,
        borderRadius: borderRadius && borderRadius,
        direction: direction && direction
      }}
      aria-disabled={true}
    >
      <span className={iconContainer ? 'button__iconContainer' : ''}
        style={{
          background: requiredBackGroundColor,
          // iconContainer && (iconContainerColor !== undefined) ? iconContainerColor : color,
          width: iconContainerWidth !== undefined && iconContainerWidth,
          height: iconContainerWidth !== undefined && iconContainerWidth,
          borderRadius: '50%',
        }}
      >
        {icon ? renderBtnIcon() : null}
      </span>
      {
        buttonName === 'ArrowBackIosIcon' ? "" : <span>&nbsp;&nbsp;</span>
      }
      <span
        className='button__text'
        style={{ color: color, fontSize: fontSize, fontWeight: fontWeight }}
        // onClick={handleShow}
        >
        {
          isLoading ? <div style={{ display: 'flex', justifyContent: 'center', width: '80%' }}><SmallLoader color={requiredBackGroundColor ? '#ffffff' : ''} left={'-10px'} /></div> : name
        }

      </span>
      {
        buttonName === 'Done' && <span>&nbsp;&nbsp;</span>
      }
    </div>
  )
}

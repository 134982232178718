import * as React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import "./Table.scss";
import CheckListRowNew from './CheckListRowNew/CheckListRowNew';
import { useAdditionalCheckList } from '../../../../../hooks/useAdditionalCheckLists';
import { useTestsPerFloor } from '../../../../../hooks/useTestsPerFloor';
import { useDispatch } from 'react-redux';
import {updateSystemChecklistLocal, updateSystemChecklistServer} from '../../../../../actions/customer/orderProcessSystemActions'
import { uploadPhoto } from '../../../../../functions/api/orders'
import EmptyList from '../EmptyList/EmptyList';
import { debounce } from "lodash";
import { SYSTEM } from '../../../../../constants/translations/customersPage';
const StyledTableHeaderCell = withStyles({
    root: {
        paddingTop: '10px',
        paddingBottom: '10px', 
        borderTop: "1px solid #CACBCC",
        "&:last-child": {
            borderLeft: "1px solid #CACBCC",
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px',
        },
        "&:first-child": {
            borderRight: "1px solid #CACBCC",
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
        }
    },
})(TableCell);

const StyledTableContainer = withStyles({
    root: {
        border: 'none',
        padding: '0px',
        border: '5px solid red',
        overflowX: "initial",
        boxShadow: 'none'
    }
}) (TableContainer);



const useStyles = makeStyles((theme) => ({
    tableContainer: {
        border: 'none',
        background: 'transparent'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '0px 10px',
        border: 'none',
    },
    tableHead: {
        backgroundColor: '#EEEEEE',
    },
    tableRow: {
        maxHeight: '20px'
    },
    headerCell: {
        fontSize: '14px',
        fontWeight: 800,
        color: '#455768',
        fontFamily: 'Rubik !important',
    },
    bodyCell: {
        fontSize: '14px',
        fontWeight: 400,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        position: 'relative',
        maxWidth: '100px',
        maxHeight: '100px',
        background: '#fff',
    },
    input: {
        marginTop: '2px',
        width: '100%',
        padding: '6px',
        border: '1px solid #CACBCC',
        borderRadius: '5px',
        color: '#CACBCC',
    },
    selectInputContainer: {
        position: 'absolute',
        top: '12px',
        left: '0px'
    }

}));

export default function CheckListTable(props) {
    let { data, orderId, process, rows, columns, checkListId,totalProgress } = props;
    const classes = useStyles();


    const dispatch = useDispatch()

    // temporarily variable
    let union_check_list = true;
    let actual_system_id = data.actualSystemId;
    const orderProcessId = process?._id;

    const { setRowTitle} = useAdditionalCheckList(orderId, actual_system_id, union_check_list)
    const { getTestsPerFloor} = useTestsPerFloor(actual_system_id)

    const debouncedUpdate = debounce((dataId, valueId, value ) => {
        dispatch(updateSystemChecklistServer({ dataId, valueId, value, checkListId, orderProcessId, checkListProgress: totalProgress }));
    }, 1500);

    const newSetValueHandler = async (rowIdx, colIdx, value) => {
        dispatch(updateSystemChecklistLocal({ rowIdx, colIdx, value }));
    }

    const setValueHandler = async (rowIdx, colIdx, value) => {
        const dataId = rows[rowIdx]._id;
        const valueId = rows[rowIdx].values[colIdx]._id
        debouncedUpdate(dataId, valueId, value);
    }

    const uploadPhotoHandler = async (rowIdx, colIdx, element) => {
        if(element?.files[0]?.size >= 10506317) {    //10 MB
          console.log('TOO big image')
        }else {
        const dataId = rows[rowIdx]._id;
        const valueId = rows[rowIdx].values[colIdx]._id
          const file = element.files[0]
          const old_values = rows[rowIdx].values.filter((item) => item.typeOfInput == 'photo')[0].value
          let reader = new FileReader();
          reader.readAsDataURL(file)
          reader.onloadend = () => {
            const image = reader.result;
            const formData = new FormData()
            formData.append('photo',file);
             uploadPhoto(formData).then(({ result }) => {
                console.log('uploaded', result)
                debouncedUpdate(dataId, valueId, [...old_values, result]);
                dispatch(updateSystemChecklistLocal({rowIdx, colIdx, value: [...old_values, result]}))
            });
            dispatch(updateSystemChecklistLocal({rowIdx, colIdx, value: [...old_values, image]}))
          }
        }
    }
    
    function removeFileHandler(rowIdx, colIdx, e) {

    }
    if(!rows.length) {
        return( <EmptyList name={SYSTEM} />)
    }
   
    return (
    <>
            <StyledTableContainer component={Paper} style={{ position: "sticky", top: 0, border: 'none' }} className={classes.tableContainer}>
                <Table
                    aria-label="caption table" size='small'
                    className={classes.table}
                    stickyHeader
                >
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            {
                                columns.filter(item => item.field !== 'progress')?.map((col, index) => {
                                    return <StyledTableHeaderCell key={index} align="right" className={classes.headerCell}>{col.headerName}</StyledTableHeaderCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    {
                        rows &&

                        <TableBody>
                            {rows.map((row, rowIdx) => {
                                let newRow = {};
                                newRow.values = row;
                                return <CheckListRowNew
                                    key={rowIdx}
                                    row={newRow.values}
                                    rowTitle={newRow.row_title}
                                    rowIdx={rowIdx}
                                    setValueHandler={setValueHandler}
                                    newSetValueHandler={newSetValueHandler}
                                    uploadPhotoHandler={uploadPhotoHandler}
                                    removeFileHandler={removeFileHandler}
                                    orderId={orderId}
                                    setRowTitle={setRowTitle}
                                    getTestsPerFloor={getTestsPerFloor}
                                />

                        
                            })}
                        </TableBody>
                    }
                </Table>
                {
                    !rows && <div className="table__emptyContainer"><p className='table__noItem'>There is no data</p></div>
                }
            </StyledTableContainer>
            {/* // </Paper> */}
        </>
    );
}
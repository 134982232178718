export const GET_SYSTEMS_CHECK_LIST_MOBILE = "GET_SYSTEMS_CHECK_LIST_MOBILE";
export const GET_SYSTEMS_CHECK_LIST_TABLET = "GET_SYSTEMS_CHECK_LIST_TABLET";
export const GET_SYSTEMS_BY_ORDER_ID = "GET_SYSTEMS_BY_ORDER_ID";
export const GET_SYSTEMS_FOR_ACCORDION = "GET_SYSTEMS_FOR_ACCORDION";
export const SET_SYSTEMS_FOR_ACCORDION = "SET_SYSTEMS_FOR_ACCORDION";
export const ADD_SYSTEM = "ADD_SYSTEM";
export const SELECTED_SYSTEM_BY_SYSTEM_ID = "SELECTED_SYSTEM_BY_SYSTEM_ID";
export const EDIT_SYSTEM = "EDIT_SYSTEM";
export const DELETE_SYSTEM = "DELETE_SYSTEM";
export const GET_CHECK_LIST_BY_SYSTEM_ID = "GET_CHECK_LIST_BY_SYSTEM_ID";
export const GET_SYSTEM_PROGRESS_BY_SYSTEM_ID = "GET_SYSTEM_PROGRESS_BY_SYSTEM_ID";
export const MOVE_SYSTEM_ROW = "MOVE_SYSTEM_ROW";
export const ADD_CHECK_LIST = "ADD_CHECK_LIST";
export const SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH = "SET_SYSTEM_FOR_SYSTEMS_OPENING_PATH";
export const REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH = "REMOVE_SYSTEM_FROM_SYSTEMS_OPENING_PATH";
export const ADD_SYSTEM_MEASUREMENT = "ADD_SYSTEM_MEASUREMENT";
export const UPDATE_SYSTEM_CHECKLIST = "UPDATE_SYSTEM_CHECKLIST";
export const SHOW_SYSTEMS_POP_UP = "SHOW_SYSTEMS_POP_UP";
export const RESET_SYSTEMS_FOR_ACCORDION = "RESET_SYSTEMS_FOR_ACCORDION";
